import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Switch, Space } from "antd";
import api from '../../utils/api';
import { toast } from 'react-toastify';

const EditRetailBranch = ({ toggle, branchDetails, fetchBranches, fetchBranchDetails,branches }) => {
    const [name, setName] = useState(branchDetails?.name || '');
    const [email, setEmail] = useState(branchDetails?.email || '');
    const [domain, setDomain] = useState(branchDetails?.domain || '');
    const [phone, setPhone] = useState(branchDetails?.phone || '');
    const [address, setAddress] = useState(branchDetails?.address || '');
    const [country, setCountry] = useState(branchDetails?.country || '');
    const [town, setTown] = useState(branchDetails?.town || '');
    const [city, setCity] = useState(branchDetails?.city || '');
    const [zipCode, setZipCode] = useState(branchDetails?.zip_code || '');
    const [ghanaPost, setGhanaPost] = useState(branchDetails?.ghana_post || '');
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('');
    const [branchStatus, setBranchStatus] = useState(branchDetails?.status);
    const [loading, setLoading] = useState(false);
    const { token } = useSelector((state) => state.auth)

    const URL = api_url + `/api/v1/retailer/admin/${branchDetails.admin_id}/branch/${branchDetails.id}`

    const UpdateBranch = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const branchDetails = {
            name,
            email,
            domain,
            phone,
            address,
            country,
            town,
            city,
            status:branchStatus,
            zip_code: zipCode,
            ghana_post: ghanaPost,
        }
        const required = !name || !email || !address || !country || !city;
        if (required) {
            setError(`Please fill all required field`)
            setTimeout(() => {
                setError('');
            }, 6000);
            return
        }
        try {
            setLoading(true)
            const response = await api.put(URL, branchDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            setStatus('Branch updated successfully')
            fetchBranches()
            fetchBranchDetails()
            setName('')
            setAddress('')
            setPhone('')
            setEmail('')
            setCountry('')
            setCity('')
            setTown('')
            setZipCode('')
            setGhanaPost('')
            setDomain('')
            setTimeout(() => {
                toggle()
            }, 500);
            toast.success('Branch updated successfully')
        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            toast.error(error?.response?.data?.error)

        } finally {
           
            setLoading(false)
        }
    }

    const handleBranchStatus = (checked) => {
        const status = checked ? 'active' : 'inactive';
        setBranchStatus(status);
    
    }

    const checker = branchDetails.status === 'active';
 
    
   
    

    return (
        <div className='max-w-2xl h-[50vh] sm:h-[65vh] overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
            <div className=' mb-6 flex justify-between  items-center '>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Edit Branch</p>
                <div className='flex items-center gap-3 '>
                    <label className="text-sm font-lufga" >Status</label>
                        <Switch
                        defaultChecked={checker}
                         checkedChildren="active" 
                         unCheckedChildren="inactive" 
                         onClick={handleBranchStatus}
                         />
                </div>
            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-2">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#059212" loading={loading} size={36} />
                    </div>
                )}

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Branch Name<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder="Enter branch name"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Address<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => { setAddress(e.target.value) }}
                        placeholder="123 ABC street"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Phone Number<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        placeholder="Enter phone number"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder="Enter branch email"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Country<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="e.g Ghana"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" >City <span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => { setCity(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Town</label>
                    <input
                        type="text"
                        value={town}
                        onChange={(e) => setTown(e.target.value)}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Zip Code</label>
                    <input
                        type="text"
                        value={zipCode}
                        onChange={(e) => { setZipCode(e.target.value) }}
                        placeholder="Enter zip code"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Ghana Post</label>
                    <input
                        type="text"
                        value={ghanaPost}
                        onChange={(e) => { setGhanaPost(e.target.value) }}
                        placeholder="e.g GL-038-9022"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga" >Website</label>
                    <input
                        type="text"
                        value={domain}
                        onChange={(e) => { setDomain(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"

                    />
                </div>
                


            </form>
            <div className='flex justify-between items-center'>
                <button onClick={UpdateBranch} className="bg-green-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-green-600 shadow-md transition duration-300">Update Branch</button>

                {status ? <div>
                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}
            </div>

        </div>
    );
}



export default EditRetailBranch;
