import axios from 'axios';
import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { api_url } from '../../config';

const ForgotRetailPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [status,setStatus] = useState('')


    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };
    const handleSendEmail = async (e) => {
        e.preventDefault()
        const form = {
            email
        }
        try {
            setLoading(true);
            const response = await axios.post(api_url+'/api/retailer/admin/forgot-password', form, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            // dispatch(setAuthData(response.data)) 
            if (response.status == 200) {
                setStatus('The link has been sent to your email')
                setTimeout(() => {
                    setStatus('');
                }, 6000);

            }
            // navigate('/dashboard')

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
        } finally {
            setLoading(false);
            setEmail('')
        }

    }
    return (
        <div className=' m-2 shadow px-6 py-4 rounded-md'>
            <form className='space-y-4 md:space-y-6' >
                <div>
                    <p className='text-xl font-lufga font-medium text-neutral-600 text-center mb-4'>Forgot Password?</p>
                    <p className='text-sm font-lufga text-neutral-600 text-center'>Enter the email you registered with and we will send you a link to reset your password</p>
                </div>
                <div>
                    <label
                        htmlFor='email'
                        className='block font-lufga mb-2 text-sm font-medium text-gray-900 '
                    >
                        Email
                    </label>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='email'
                        name='email'
                        id='email'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:outline-none  block w-full p-2.5 '
                        placeholder='admin@company.com'
                        required
                    />
                </div>
                {status ? <div>
                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}
                {/* <div>
                    <p className='text-sm text-neutral-700 mb-4'>Choose Product You're Subscribed to</p>
                    <div className='flex gap-4 items-center'>
                        <div className='flex items-center gap-2'>
                            <input
                                type='radio'
                                id='wholesale'
                                name='subscription'
                                checked={selectedOption === 'wholesale'}
                                onChange={() => handleOptionChange('wholesale')}
                            />
                            <label htmlFor='wholesale' className='text-xs text-neutral-700'>
                                Wholesale
                            </label>
                        </div>
                        <div className='flex items-center gap-2'>
                            <input
                                type='radio'
                                id='retail'
                                name='subscription'
                                checked={selectedOption === 'retail'}
                                onChange={() => handleOptionChange('retail')}
                            />
                            <label htmlFor='retail' className='text-xs text-neutral-700'>
                                Retail
                            </label>
                        </div>
                    </div>

                </div> */}
               

                <button
                onClick={handleSendEmail}

                    className='w-full mt-8 text-white bg-green-500 font-lufga hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center '
                >

                    Send email
                </button>
                {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                                        <InfinitySpin color="#387ADF" loading={loading} size={32} />
                                    </div>) : null}
                <div className='flex justify-between'>
                    <Link to='/'>
                        <p className='hover:underline cursor-pointer text-sm text-green-800 font-lufga'>Go back</p>
                    </Link>

                </div>
            </form>
        </div>
    );
}

export default ForgotRetailPassword;
