import React, { useEffect, useRef, useState } from 'react';
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { clearAuthData, logoutState } from '../../Redux/AuthSlice';
import { CgProfile } from "react-icons/cg";
import { api_url } from '../../config';
import wlogo from '../../assets/wlogo.png';
import api from '../../utils/api';
import { toast } from 'react-toastify';

const Header = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { email_verified_at } = useSelector((state) => state.auth)
    const auth = useSelector((state) => state.auth)
    const token = useSelector((state) => state.auth.token)

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownVisible(!isDropdownVisible);
    };

    const navigateToProfile = (e) => {
        e.stopPropagation();
        navigate('/wholesale/profile');
        setDropdownVisible(false);
    }

    const handleLogout = async (e) => {
        e.stopPropagation();
        try {
            dispatch(logoutState(true));
            await api.post(api_url + '/api/wholesaler/admin/logout', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            dispatch(clearAuthData());

            window.history.pushState(null, '', '/');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/');
            };
            dispatch(logoutState(false));
            navigate('/', { replace: true });
            toast.success("Logged out succesfully")
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const dropdownRef = useRef(null);
    
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function getInitials(name) {
        const words = name?.split(' ');
        const initials = words?.map(word => word?.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const name = auth.name;
    const initials = getInitials(name);

    return (
        <div className='bg-neutral-50'>
            <div className='flex justify-between items-center px-2 sm:px-8 py-1'>
                <p className='text-xs sm:text-sm sm:flex gap-2'>
                    <span className='font-lufga flex items-center gap-2'>
                        <img src={wlogo} className='w-[30px] shadow-md' alt="Logo" />
                        Pharmtrix {(auth.product_name).split('-')[1]}
                    </span>
                    || <span className='font-lufga'>{auth.company_name}</span>
                </p>
                <div className='flex flex-col justify-center items-center'>
                    <div className='relative font-lufga' ref={dropdownRef}>
                        <button
                            className='px-1 py-2.5 border h-10 w-10 rounded-full text-xs text-center sm:text-sm cursor-pointer'
                            onClick={toggleDropdown}
                        >
                            {initials}
                        </button>
                        {isDropdownVisible && (
                            <div className='absolute flex flex-col top-12 right-0 bg-white border rounded shadow-md p-2 min-w-[150px] z-50'>
                                <button 
                                    onClick={handleLogout} 
                                    className='w-full text-left cursor-pointer hover:bg-slate-100 flex gap-2 items-center p-2 rounded transition-colors duration-200'
                                >
                                    <IoLogOutOutline size={22} />
                                    <span className='text-xs sm:text-sm'>Logout</span>
                                </button>
                                <button
                                    onClick={navigateToProfile}
                                    className='w-full text-left cursor-pointer hover:bg-slate-100 flex gap-2 items-center p-2 rounded transition-colors duration-200'
                                >
                                    <CgProfile size={22} />
                                    <span className='text-xs sm:text-sm'>Profile</span>
                                </button>
                            </div>
                        )}
                    </div>
                    {!email_verified_at || email_verified_at === 'null' ? 
                        <p className='text-xs text-red-500 font-lufga'>Not verified</p> : 
                        <p className='text-xs font-lufga text-green-500'>verified</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default Header;