import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaUser, FaEnvelope, FaPhone, FaBuilding, FaEdit } from 'react-icons/fa';
import { api_url } from '../../config';
import api from '../../utils/api';
import wlogo from '../../assets/wlogo.png'
import { InfinitySpin } from 'react-loader-spinner';
import { MdOutlineSecurity } from 'react-icons/md';


const Profile = ({onEdit,onChangePassword,data}) => {
    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-md rounded-xl shadow-xl bg-white overflow-hidden">
                <div className="relative h-32 bg-gradient-to-r from-blue-300 to-blue-700">
                    <div className="absolute -bottom-16 left-1/2 transform -translate-x-1/2">
                        <img
                            className="w-24 h-24 rounded-full border-4 border-white shadow-lg object-cover"
                            src={wlogo}
                            alt={data?.name}
                        />
                    </div>
                </div>
                <div className="pt-16 pb-6 px-6 text-center">
                    <h1 className="text-2xl font-semibold text-gray-900">{data?.name}</h1>
                    <p className="text-sm text-gray-600 mt-1">Administrator</p>
                    <div className="mt-4 space-y-4">
                        <ProfileItem icon={<FaBuilding />} label="Company" value={data?.company_name} />
                        <ProfileItem icon={<FaEnvelope />} label="Email" value={data?.email} />
                        {/* <ProfileItem icon={<FaPhone />} label="Phone" value={data?.phone} /> */}
                        <span
                           onClick={onChangePassword}
                        className='mt-8 flex justify-center items-center  hover:underline cursor-pointer hover:bg-gray-50 py-1 px-2 rounded-md'>
                        <MdOutlineSecurity size={16} />
                            <p className='text-xs'>Update Password?</p>
                        </span>
                    </div>
                    <button
                    onClick={onEdit}
                    className="mt-2 px-6 py-3 bg-blue-500 text-white rounded-full font-medium shadow-md hover:bg-blue-600 transition duration-300 ease-in-out flex items-center justify-center w-full">
                        <FaEdit className="mr-2" />
                        Edit Profile
                    </button>
                </div>
            </div>
        </div>
    );
};

const ProfileItem = ({ icon, label, value }) => (
    <div className="flex items-center space-x-3 text-gray-700">
        <div className="text-blue-500">{React.cloneElement(icon, { size: 20 })}</div>
        <div className="flex-1 text-left">
            <p className="text-xs text-gray-500">{label}</p>
            <p className="font-medium">{value}</p>
        </div>
    </div>
);

export default Profile;