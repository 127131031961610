import React from 'react';
import ResetPassword from '../../components/wholesaleLib/reset-password';

const ResetPasswordPage = () => {
    return (
        <div className=' flex justify-center items-center bg-gray-50 h-screen w-full '>
            <ResetPassword/>
        </div>
    );
}

export default ResetPasswordPage;
