import React, { useEffect, useState } from 'react';
import { FaRegEyeSlash } from 'react-icons/fa';
import { IoEyeOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuthData } from '../Redux/AuthSlice';
import { api_url, sub_api_url } from '../config';
import { InfinitySpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
const SignupForm = () => {
    const [code, setCode] = useState('')
    const [company, setCompany] = useState('')
    const [product, setProduct] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirm_password] = useState('')
    const [terms, setTerm] = useState(false)
    const [passwordState, setPasswordState] = useState(false)
    const [confirm_passwordSate, setConfirm_passwordState] = useState(false)
    const [subscriptionInfo, setSubscriptionInfo] = useState("")
    const [codeinput, setCodeinput] = useState(false);
    const [error, setError] = useState("")
    const [codeError, setCodeError] = useState("")
    const [loading, setLoading] = useState(false);



    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState)
    }
    const handleConfirmPasswordToggle = (e) => {
        e.preventDefault();
        setConfirm_passwordState(!confirm_passwordSate)
    }
    const isValidName = (name) =>  /^[A-Za-z\ /-]+$/.test(name);
    const isValidInput = (input) => /^[A-Za-z0-9\ /-]+$/.test(input);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleFnameChange = (e) => {
        const newName = e.target.value;
        if (isValidName(newName) || newName === '') {
            setFname(newName);
        }
    };
    const handleLnameChange = (e) => {
        const newName = e.target.value;
        if (isValidName(newName) || newName === '') {
            setLname(newName);
        }
    };
    const handleCompanyChange = (e) => {
        const newCompany = e.target.value;
        if (isValidInput(newCompany) || newCompany === '') {
            setCompany(newCompany);
        }
    };

    //fetching the company name and product name when code is inputted
    const fetchData = async () => {
        if (!code) {
            setCompany('')
            setProduct('')
                 return; // If code is empty, return early
                }
        try {
            const response = await axios.get(`${sub_api_url}/api/v1/subscription/${code}`);
            
            setSubscriptionInfo(response.data);
            setCompany(response?.data.data.user?.company_name);
            setProduct((response?.data.data.plan?.app));
            setCodeError(null);
        } catch (error) {
            setCodeError(error);
            setCompany('')
            setProduct('')
        }
    };
    useEffect(() => {
        fetchData();
    }, [code]);


    const handleSignUp = async (e) => {
        e.preventDefault()
        setLoading(true);
        if (product === 'pharmtrix-retail') {

            const retail_form = {
                sub_code: code,
                company_name: company,
                product_name: product,
                name: lname + ' ' + fname,
                email,
                password,
                password_confirmation: confirm_password

            }
            try {
                setLoading(true);
                const response = await axios.post(api_url + '/api/retailer/admin/register', retail_form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                });
                toast.success('Sign-up successfully')
                dispatch(setAuthData(response.data))
                navigate(`/retail/dashboard`)
            } catch (error) {
                console.log(error);
                setError(error?.response?.data?.error)
                setTimeout(()=>{
                    setError("")
                },3000)
                toast.error('An error occurred')
            } finally {
                setLoading(false);
            }
        }
        else if (product === 'pharmtrix-wholesale') {

            const wholesale_form = {
                sub_code: code,
                company_name: company,
                product_name: product,
                name: fname + ' ' + lname,
                email,
                password,
                password_confirmation: confirm_password

            }
            try {
                setLoading(true);
                const response = await axios.post(api_url + '/api/wholesaler/admin/register', wholesale_form, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                toast.success('Sign-up successfully')
                dispatch(setAuthData(response.data))
                navigate('/wholesale/dashboard')
            } catch (error) {
                console.log(error);
                setError(error?.response?.data?.error)
                setTimeout(()=>{
                    setError("")
                },3000)
                toast.error('An error occurred')
            } finally {
                setLoading(false);
            }
        }
        else{
            setError('Product type invalid')
            setTimeout(()=>{
                setError("")
            },3000)
        }
        setLoading(false);
    }

  



    return (
        <div >
            <section className="bg-gray-100 h-full md:h-full md:min-h-screen  w-full">
                <div className="flex flex-col items-center justify-center px-6 py-8 lg:py-6">
                    {/* <div className="flex items-center gap-1 mb-6 text-2xl font-semibold text-gray-900 ">
                        <FcBullish size={24} />
                        <p className='font-semibold text-3xl'>Pharmtrix</p>
                    </div> */}
                    <div className="w-full bg-white rounded-lg shadow dark:border  sm:max-w-md md:max-w-xl xl:p-0 ">
                        <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
                            <div className='flex items-center justify-between'>
                                <h1 className="text-xl font-bold leading-tight tracking-tight font-lufga text-gray-900 md:text-2xl ">
                                    Setup Account
                                </h1>
                                {/* {error && (
                                    <div>
                                        <p className="bg-red-300 rounded-md shadow-md font-lufga p-3 my-2">
                                            {typeof error === 'string' ? error : (
                                                Object.keys(error).map((key) => (
                                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                                ))
                                            )}
                                        </p>
                                    </div>
                                )} */}
                            </div>

                            <form>
                                <div className="grid gap-2 mb-6 md:grid-cols-2">
                                    <div>
                                        <label className="flex items-center mb-2 text-sm font-medium text-gray-900 font-lufga gap-1 ">Subscription Code<span className='font-serif text-red-400'>*</span></label>
                                        <input
                                            type="text"
                                            value={code}
                                            onChange={(e) => { setCode(e.target.value) }}
                                            className={`bg-gray-50 border ${codeinput ? 'focus:outline-none' : 'border-gray-300'} text-gray-900 focus:outline-none text-sm rounded-lg block w-full p-2.5   `}
                                            placeholder=""
                                            required

                                            onFocus={() => setCodeinput(true)}
                                            onBlur={() => setCodeinput(false)}
                                        />


                                    </div>
                                    <div>
                                        <p className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Click button to get code</p>
                                        <button
                                            onClick={() => { window.open('https://test3.ntubedglobal.com', '_blank'); }}
                                            type='button'
                                            className="bg-gradient-to-r from-green-400 font-lufga to-green-600 focus:outline-none text-white text-xs rounded-md py-2 px-4 hover:opacity-90 "
                                        >
                                            Dont have a Subscription Code?
                                        </button>
                                    </div>

                                    <div>
                                        <label htmlFor="company" className="flex items-center gap-1 mb-2 text-sm font-medium text-gray-900 font-lufga ">Company<span className='font-serif text-red-400'>*</span></label>
                                        <input
                                            type="text"
                                            value={company}
                                            onChange={handleCompanyChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5  "
                                            placeholder="Automatically show based on Code"
                                            required
                                            disabled={true}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="company" className="flex items-center gap-1 mb-2 text-sm font-medium text-gray-900 font-lufga">Product<span className='font-serif text-red-400'>*</span></label>
                                        <input
                                            type="text"
                                            value={product}
                                            onChange={(e) => { setProduct(e.target.value) }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5  "
                                            placeholder="Automatically show based on Code"
                                            required
                                            disabled={true}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="first_name" className="flex items-center gap-1 mb-2 text-sm font-medium font-lufga text-gray-900 ">Admin first name<span className='font-serif text-red-400'>*</span></label>
                                        <input
                                            type="text"
                                            value={fname}
                                            onChange={handleFnameChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 "
                                            placeholder="John"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="last_name" className="flex items-center gap-1 mb-2 text-sm font-medium text-gray-900 font-lufga">Admin last name<span className='font-serif text-red-400'>*</span></label>
                                        <input
                                            type="text"
                                            value={lname}
                                            onChange={handleLnameChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none rounded-lg block w-full p-2.5 "
                                            placeholder="Doe"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="email" className="flex items-center gap-1 mb-2 text-sm font-medium text-gray-900 font-lufga">Email address<span className='font-serif text-red-400'>*</span></label>
                                    <input
                                        type="email"
                                        value={email}
                                        name='email'
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 "
                                        placeholder="john.doe@company.com"
                                        required
                                    />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="password" className="flex items-center gap-1 mb-2 text-sm font-medium text-gray-900 font-lufga">Password<span className='font-serif text-red-400'>*</span></label>
                                    <div className='flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none w-full p-2'>
                                        <input
                                            type={passwordState ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => { setPassword(e.target.value) }}
                                            className="w-full bg-gray-50 focus:outline-none "
                                            placeholder="•••••••••"
                                            required
                                        />
                                        {passwordState ? <IoEyeOutline size={20} onClick={handlePasswordToggle} /> : <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />}
                                    </div>

                                </div>
                                <div className="mb-6">
                                    <label htmlFor="confirm_password" className="flex items-center gap-1 mb-2 text-sm font-medium text-gray-900 font-lufga">Confirm password<span className='font-serif text-red-400'>*</span></label>
                                    <div className='flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 '>
                                        <input
                                            type={confirm_passwordSate ? "text" : "password"}
                                            value={confirm_password}
                                            onChange={(e) => { setConfirm_password(e.target.value) }}
                                            className="w-full bg-gray-50 focus:outline-none"
                                            placeholder="•••••••••"
                                            required
                                        />
                                        {confirm_passwordSate ? <IoEyeOutline size={20} onClick={handleConfirmPasswordToggle} /> : <FaRegEyeSlash size={20} onClick={handleConfirmPasswordToggle} />}
                                    </div>

                                </div>
                                <div className="flex items-start mb-6">
                                    <div className="flex items-center h-5">
                                        <input

                                            type="checkbox"
                                            value={terms}
                                            onChange={() => setTerm(!terms)}
                                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:outline-none "
                                            required
                                        />
                                    </div>
                                    <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 font-lufga ">
                                        I agree with the <a href="#" className="text-blue-600 hover:underline ">terms and conditions</a>.
                                    </label>
                                </div>
                                <div className='flex justify-between items-center '>
                                    <div className='flex items-center gap-4'>
                                        <button
                                            onClick={handleSignUp}
                                            disabled={!terms}
                                            type="submit"
                                            className={`text-white bg-gradient-to-r from-blue-400 to-blue-700 hover:opacity-90 focus:ring-4 focus:outline-none font-lufga font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ${!terms ? 'cursor-not-allowed opacity-50 hover:opacity-50' : ''}`}
                                        >
                                            Submit
                                        </button>
                                        <Link to='/'>
                                            <button
                                                type="button"
                                                className="text-white bg-gradient-to-r from-neutral-400 to-neutral-700 hover:opacity-90 focus:ring-4 focus:outline-none font-lufga font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                                            >
                                                Go back
                                            </button></Link>

                                    </div>
                                   
                                </div>
                                <div>
                                        {error && (
                                            <div>
                                                <p className="bg-red-300 rounded-md shadow-md font-lufga p-3 my-2">
                                                    {typeof error === 'string' ? error : (
                                                        Object.keys(error).map((key) => (
                                                            Array.isArray(error[key]) ? error[key][0] : error[key]
                                                        ))
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                <div className='flex flex-col item-center gap-4'>
                    <InfinitySpin color="#387ADF" loader={loading} size={32} />
                    <p className='text-center '>Please wait....</p>
                </div>

            </div>) : null}
        </div>
    );
}

export default SignupForm;
