import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2'
import { InfinitySpin } from 'react-loader-spinner';
import api from '../../utils/api';
import { api_url } from '../../config';
import { FaRegBuilding, FaRegUser, FaUserSlash, FaArchive } from 'react-icons/fa';
import { toast } from 'react-toastify';

const RetailOverview = () => {
    const { id, token } = useSelector((state) => state.auth)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {
        fetchOverview()
    }, [id, token])

    const fetchOverview = async () => {
        try {
            setLoading(true)
            const response = await api.get(api_url + `/api/v1/retailer/admin/${id}/overview`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setData(response.data.data);
        } catch (error) {
            setError(error);
            console.log(error);
            toast.error('An error occurred')
        } finally {
            setLoading(false)
        }
    };

    const chartData = {
        labels: ['Branches', 'Users'],
        datasets: [
            {
                label: 'Active',
                data: [data.activeBranches, data.totalActive],
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderWidth: 1,
                borderColor:'rgb(75, 192, 192)',
                borderRadius:10
            },
            {
                label: 'Inactive',
                data: [data.inactiveBranches, data.totalInactive],
                backgroundColor:  'rgba(255, 99, 132, 0.2)',
                 borderWidth: 1,
                borderColor: 'rgb(255, 99, 132)',
                borderRadius:10

            },
            {
                label: 'Archived',
                data: [0, data.totalArchive],
                backgroundColor: 'rgba(201, 203, 207, 0.2)',
                 borderWidth: 1,
                borderColor:  'rgb(201, 203, 207)',
                borderRadius:10
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Branch & User Statistics',
                font: {
                    size: 18,
                    weight: 'bold',
                },
            },
        },
    };

    const StatCard = ({ icon: Icon, value, label, color }) => (
        <div className={`p-4 bg-white shadow-lg rounded-xl flex items-center justify-between transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl border-l-[1px] border-${color}-500`}>
          <div className="flex items-center">
            <div className={`p-3 rounded-full bg-${color}-100 mr-4`}>
              <Icon className={`text-${color}-500 text-2xl`} />
            </div>
            <div>
              <p className="text-xl font-bold text-gray-800">{value}</p>
              <p className="text-xs text-gray-500 font-lufga uppercase tracking-wider">{label}</p>
            </div>
          </div>
         
        </div>
      );

    const remainingBranchSlots = data?.branches_limit - data?.branches;
    const remainingUserSlots = data?.users_limit - data?.totalUsers;

    // Calculate percentages for progress bars
    const branchUsagePercentage = (data.branches / data.branches_limit) * 100;
    const userUsagePercentage = (data.totalUsers / data.users_limit) * 100;

    return (
        <div className='w-full'>
            {loading && (
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
            )}
            <div className='my-4'>
                <h1 className='text-3xl font-bold text-gray-800'> Overview</h1>
            </div>
            {!loading && (
                <div className="bg-gray-100 rounded-lg p-6">
                    {/* Branches Overview */}
                    <h2 className="text-2xl font-semibold mb-2 text-gray-700">Branches</h2>
                    <div className='mb-2'>
                        <p className="text-gray-500 font-lufga text-xs">Maximum branches: <span className='font-bold'>{data?.branches_limit}</span></p>
                        <p className="text-gray-500 font-lufga text-xs">Remaining branch slots: <span className='font-bold'>{remainingBranchSlots}</span></p>
                    </div>
                    <div className="w-1/4 bg-gray-200 rounded-full h-3 mb-2">
                        <div
                            className="bg-gradient-to-r from-blue-600 to-green-500 h-3 rounded-full"
                            style={{ width: `${branchUsagePercentage}%` }}
                        ></div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
                        <StatCard icon={FaRegBuilding} value={data?.branches} label="Total Branches" color="blue" />
                        <StatCard icon={FaRegBuilding} value={data?.activeBranches} label="Active Branches" color="green" />
                        <StatCard icon={FaRegBuilding} value={data?.inactiveBranches} label="Inactive Branches" color="red" />
                        {/* <StatCard icon={FaRegBuilding} value={data.branches_limit} label="Branch Limit" color="gray" /> */}
                    </div>

                    {/* Users Overview */}
                    <h2 className="text-2xl font-semibold mb-2 text-gray-700">Users</h2>
                    <div className='mb-2'>
                        <p className="text-gray-500 font-lufga text-xs">Maximum users: <span className='font-bold'>{data?.users_limit}</span></p>
                        <p className="text-gray-500 font-lufga text-xs">Remaining user slots: <span className='font-bold'>{remainingUserSlots}</span></p>
                    </div>
                    <div className="w-1/4 bg-gray-200 rounded-full h-3 mb-2">
                        <div
                            className="bg-gradient-to-r from-blue-600 to-green-500 h-3 rounded-full"
                            style={{ width: `${userUsagePercentage}%` }}
                        ></div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                        <StatCard icon={FaRegUser} value={data?.totalUsers} label="Total Users" color="blue" />
                        <StatCard icon={FaRegUser} value={data?.totalActive} label="Active Users" color="green" />
                        <StatCard icon={FaUserSlash} value={data?.totalInactive} label="Inactive Users" color="red" />
                        <StatCard icon={FaArchive} value={data?.totalArchive} label="Archived Users" color="gray" />
                    </div>

                    {/* Chart Section */}
                    <div className="bg-white p-6 shadow-lg rounded-lg">
                        <div className="h-80">
                            <Bar data={chartData} options={chartOptions} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RetailOverview;