import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { api_url } from '../../config';
import axios from 'axios';
import api from '../../utils/api';
import { toast } from 'react-toastify';

const ArchiveUserModal = ({toggle,user,fetchUsers}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [archive_status, setArchive_status] = useState('')
    const { token ,id} = useSelector((state) => state.auth)
 

    const archiveUser = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const userDetails = {
            first_name:user?.first_name,
            last_name:user?.last_name,
            email: user?.email,
            phone:user?.phone,
            title:user?.title,
            roles:user?.roles,
            status: "archived"
        }
        try {
            setLoading(true)
            const response = await api.put(api_url + `/api/v1/wholesaler/admin/${id}/branch/${user?.branch_id}/user/${user.id}`, userDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            setArchive_status('User archived successfully')
            toast.success('User archived successfully')
            fetchUsers()
            setTimeout(() => {
                toggle()
            }, 500);

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            toast.error(error?.response?.data?.error)

        } finally {     
            setLoading(false)
        }
    }
    const activateUser = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const userDetails = {
            first_name:user?.first_name,
            last_name:user?.last_name,
            email: user?.email,
            phone:user?.phone,
            title:user?.title,
            status: "active",
            roles:user?.roles
        }
        try {
            setLoading(true)
            const response = await api.put(api_url + `/api/v1/wholesaler/admin/${id}/branch/${user?.branch_id}/user/${user.id}`, userDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            setArchive_status('User re-activated successfully')
            toast.success('User re-activated successfully')
            fetchUsers()
            setTimeout(() => {
                toggle()
            }, 500);

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            toast.error(error?.response?.data?.error)

        } finally {     
            setLoading(false)
        }
    }
    
    return (
        <div>
            <div className="card sm:w-96 bg-neutral text-neutral-content min-w-max">
                {user?.status === "archived" ? <div className="card-body items-center text-center">
                    {loading && ( // Display loader while loading
                        <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                            <InfinitySpin color="#008DDA" loading={loading} size={36} />
                        </div>
                    )}
                    <h2 className="font-bold">Reactivate User</h2>

                    <div>
                        <p className='text-xs sm:text-sm'>This action will re-active the user</p>
                    </div>
                    <div className="flex items-center justify-center gap-3 my-2">
                        <button onClick={() => { toggle() }} className="bg-gray-500 font-lufga text-white text-xs sm:text-sm py-1 px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Cancel</button>
                        <button onClick={activateUser}  className="bg-blue-500 font-lufga text-white text-xs sm:text-sm py-1 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Confirm</button>
                    </div>
                    <div>
                        {archive_status ? <div>
                            <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{archive_status}</p>
                        </div> : error ? <div>
                            <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                                {typeof error === 'string' ? error : (
                                    Object.keys(error).map((key) => (
                                        Array.isArray(error[key]) ? error[key][0] : error[key]
                                    ))
                                )}
                            </p>
                        </div> : null}</div>
                </div>:<div className="card-body items-center text-center">
                    {loading && ( // Display loader while loading
                        <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                            <InfinitySpin color="#008DDA" loading={loading} size={36} />
                        </div>
                    )}
                    <h2 className="font-bold">Archive User</h2>

                    <div>
                        <p className='text-xs sm:text-sm'>Are you sure you want to archive this user?</p>
                    </div>
                    <div className="flex items-center justify-center gap-3 my-2">
                        <button onClick={() => { toggle() }} className="bg-gray-500 font-lufga text-white text-xs sm:text-sm py-1 px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Cancel</button>
                        <button onClick={archiveUser}  className="bg-blue-500 font-lufga text-white text-xs sm:text-sm py-1 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">YES</button>
                    </div>
                    <div>
                        {archive_status ? <div>
                            <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{archive_status}</p>
                        </div> : error ? <div>
                            <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                                {typeof error === 'string' ? error : (
                                    Object.keys(error).map((key) => (
                                        Array.isArray(error[key]) ? error[key][0] : error[key]
                                    ))
                                )}
                            </p>
                        </div> : null}</div>
                </div>}
            </div>
        </div>
    );
}

export default ArchiveUserModal;
