import React from 'react';
import ResetRetailPassword from '../../components/retailLib/resetRetailPassword';

const RetailResetPasswordPage = () => {
    return (
        <div>
            <div className=' flex justify-center items-center bg-gray-50 h-screen w-full '>
            <ResetRetailPassword/>
        </div>
        </div>
    );
}

export default RetailResetPasswordPage;
